import { Mood } from "@modules/Moods/types";
import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

type MoodItem = {
  date: Date;
  value: number;
};

type MoodChartData = {
  label: string;
  data: MoodItem[];
}[];

export interface MoodsChartProps {
  moods: Mood[];
}

export const MoodsChart: FC<MoodsChartProps> = ({ moods }) => {
  const data = useMemo(
    () =>
      moods.reduce<MoodChartData>(
        (data, { date: dateStr, sleep, mood, motivation, soreness }) => {
          const date = new Date(dateStr);
          data[0].data.push({
            date,
            value: sleep,
          });
          data[1].data.push({
            date,
            value: mood,
          });
          data[2].data.push({
            date,
            value: motivation,
          });
          data[3].data.push({
            date,
            value: 10 - soreness,
          });
          return data;
        },
        [
          {
            label: "Sleep",
            data: [],
          },
          {
            label: "Mood",
            data: [],
          },
          {
            label: "Motivation",
            data: [],
          },
          {
            label: "Muscle Recovery",
            data: [],
          },
        ]
      ),
    [moods]
  );

  const primaryAxis = useMemo(
    (): AxisOptions<MoodItem> => ({
      getValue: ({ date }) => date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<MoodItem>[] => [
      {
        getValue: ({ value }) => value,
        elementType: "area",
      },
    ],
    []
  );

  return moods.length > 0 ? (
    <Box height={200} width={400}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </Box>
  ) : null;
};
