import { ExerciseOneRepMax, MuscleStrengthTrend } from "@modules/Muscles/types";
import { Box } from "@mui/material";
import React, { FC, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

export interface MuscleStrengthTrendChartProps {
  muscleStrengthTrends: MuscleStrengthTrend[];
}

export const MuscleStrengthTrendChart: FC<MuscleStrengthTrendChartProps> = ({
  muscleStrengthTrends,
}) => {

  const data = useMemo(() => muscleStrengthTrends.map(({ name, workouts }) => ({
    label: name,
    data: workouts
  })), [muscleStrengthTrends])

  const primaryAxis = React.useMemo(
    (): AxisOptions<ExerciseOneRepMax> => ({
      getValue: ({ date }) => new Date(date),
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<ExerciseOneRepMax>[] => [
      {
        getValue: ({ estOneRepMax }) => estOneRepMax,
        elementType: 'line'
      },
    ],
    []
  );

  return (
    <Box height={200} width={400}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </Box>
  );
};
