
export const decodeBase64 = (str: string) => {
  return decodeURIComponent(window.atob(str));
};

export const convertToBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      }
    };
    reader.readAsDataURL(file);
  });

export const trimBase64 = (str: string) => {
  const searchString = "base64,";
  const pos = str.search(searchString);
  return str.substring(pos + searchString.length, str.length);
};
