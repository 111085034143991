import { ChartCard } from "@components";
import { FC } from "react";
import { DailyLogSummaryChart } from "@modules/Logs/containers";

export const DailyLogSummaryCard: FC = () => {

  return (
    <ChartCard
      title="Macros"
      defaultMenuValue={'CALORIES'}
      menuOptions={[
        {
            label: 'Gramm',
            value: 'GRAMM'
        },
        {
            label: 'Calories',
            value: 'CALORIES',
        },
      ]}
    >
      {({ value }) => <DailyLogSummaryChart mode={value} />}
    </ChartCard>
  );
};
