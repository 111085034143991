import { MuscleStrengthTrend } from "@modules/Muscles/types";
import { fetchApi } from "../../utils";

export type GetMuscleStrengthTrendConfig = {
    muscleId: number;
    accessToken: string;
}

export type GetMuscleStrengthTrendRequestResult = {
    strengthTrend: MuscleStrengthTrend[];
}

export const getMuscleStrengthTrend = async ({
    accessToken,
    muscleId,
  }: GetMuscleStrengthTrendConfig) => {
    const { strengthTrend } = await fetchApi<GetMuscleStrengthTrendRequestResult>(`/muscles/${muscleId}/strengthTrend`, {
        accessToken,
    });
    return strengthTrend;
  };