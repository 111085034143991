import { Box, BoxProps } from "@mui/material";
import { FC } from "react";

export interface PageProps extends BoxProps {}

export const Page: FC<PageProps> = ({ sx, children, ...props }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
