import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { BodyComposition } from "@modules/BodyWeight/types";
import { useQuery } from "react-query";

export type UseGetBodyCompositionConfig  = {
    startDate: string | null;
    endDate: string | null;
}

export const useGetBodyComposition = ({ startDate, endDate }: UseGetBodyCompositionConfig) => {
    const { accessToken } = useAccessTokenContext();
    const { data = [], ...query } = useQuery<BodyComposition[]>(["bodyComposition", startDate, endDate], () =>
      MyFitAppApi.getBodyComposition({ accessToken, startDate, endDate })
    );
    return { ...query, bodyCompositions: data };
}