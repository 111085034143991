import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Card, Row, Text } from "@components";
import { Key, useState } from "react";

export interface ChartCardProps<TMenuOptionItemValue> {
  title: string;
  defaultMenuValue: TMenuOptionItemValue;
  menuOptions: { label: string; value: TMenuOptionItemValue }[];
  children: ({ value }: { value: TMenuOptionItemValue }) => React.ReactNode;
}

export function ChartCard<TMenuOptionItemValue extends Key>({
  title,
  children,
  menuOptions,
  defaultMenuValue,
}: ChartCardProps<TMenuOptionItemValue>) {
  const [value, setDays] = useState<TMenuOptionItemValue>(defaultMenuValue);

  const handleChangeDays = (e: SelectChangeEvent<TMenuOptionItemValue>) => {
    const value = e.target.value;
    setDays(value as TMenuOptionItemValue);
  };

  return (
    <Card sx={{ m: 1 }}>
      <Row sx={{ justifyContent: "space-between", mb: 1 }}>
        <Text>{title}</Text>
        <Select size="small" onChange={handleChangeDays} value={value}>
          {menuOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Row>
      {children({ value })}
    </Card>
  );
}
