import { DailyActivity } from "@modules/DailyActivities/types";
import { fetchApi } from "../../utils";

export type GetDailyActivitiesRequestConfig = {
    accessToken: string;
    startDate: string | null;
    endDate: string | null;
}

export type GetDailyActivitiesRequestResult = {
  dailyActivities: DailyActivity[];
}

export const getDailyActivities = async ({
    accessToken,
    startDate,
    endDate
  }: GetDailyActivitiesRequestConfig) => {
    const { dailyActivities } = await fetchApi<GetDailyActivitiesRequestResult>("/dailyActivities", {
        accessToken,
      params: {
        startDate,
        endDate,
      },
    });
    return dailyActivities;
  };