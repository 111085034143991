import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { Muscle } from "@modules/Muscles/types";
import { useQuery } from "react-query";

export const useMuscles = () => {
    const { accessToken } = useAccessTokenContext();
    const { data = [], ...query } = useQuery<Muscle[]>(["muscles"], () =>
      MyFitAppApi.getMuscles({ accessToken })
    );
    return { ...query, muscles: data };
}