import { ChartCard } from "@components";
import { FC } from "react";
import { MoodsChart } from "@modules/Moods/containers";

type MenuOptions = {
  label: string;
  value: number | "ALL";
}[];

export const MoodsCard: FC = () => {
  return (
    <ChartCard
      title="Moods"
      defaultMenuValue={'ALL'}
      menuOptions={[
        {
          label: "30 Days",
          value: -30,
        },
        {
          label: "90 Days",
          value: -90,
        },
        {
          label: "All",
          value: "ALL",
        },
      ] as MenuOptions}
    >
      {({ value }) => <MoodsChart days={value} />}
    </ChartCard>
  );
};
