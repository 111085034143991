import dateApi from 'date-and-time';

export const formatIsoDate = (date: Date | null) => {
    if(!date) return null;
    return dateApi.format(date, 'YYYY-MM-DD')
}

export const addDays = (endDate: Date | null, days: number | null) => {
    if(!endDate || days === null) return null
    return dateApi.addDays(endDate, days);
}