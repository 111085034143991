import { DailyLogSummaryChart as DailyLogSummaryChartComponent } from "@modules/Logs/components";
import { useGetDailyLogSummary } from "@modules/Logs/hooks";
import { CircularProgress } from "@mui/material";
import { FC } from "react";

export interface DailyLogSummaryChartProps {
  mode: 'GRAMM' | 'CALORIES'
}

export const DailyLogSummaryChart : FC<DailyLogSummaryChartProps> = ({ mode }) => {
  const { isFetched, dailyLogSummaries } = useGetDailyLogSummary();

  return isFetched ? (
    <DailyLogSummaryChartComponent mode={mode} dailyLogSummaries={dailyLogSummaries} />
  ) : (
    <CircularProgress />
  );
};
