import { Muscle } from "@modules/Muscles/types";
import { fetchApi } from "../../utils";

export type GetMusclesRequestConfig = {
    accessToken: string;
}

export type GetMusclesRequestResult = {
    muscles: Muscle[];
}

export const getMuscles = async ({
    accessToken,
  }: GetMusclesRequestConfig) => {
    const { muscles } = await fetchApi<GetMusclesRequestResult>("/muscles", {
        accessToken,
    });
    return muscles;
  };