import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { Mood } from "@modules/Moods/types";
import { useQuery } from "react-query";

export type UseGetMoodsConfig  = {
    startDate: string | null;
    endDate: string | null;
}

export const useGetMoods = ({ startDate, endDate }: UseGetMoodsConfig) => {
    const { accessToken } = useAccessTokenContext();
    const { data = [], ...query } = useQuery<Mood[]>(["moods", startDate, endDate], () =>
      MyFitAppApi.getMoods({ accessToken, startDate, endDate })
    );
    return { ...query, moods: data };
}