import { BodyComposition } from "@modules/BodyWeight/types";
import { Box } from "@mui/material";
import React, { FC, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

type MyDatum = { date: Date; weight: number };

export interface BodyWeightChartProps {
  bodyCompositions: BodyComposition[];
}

export const BodyCompositionChart : FC<BodyWeightChartProps> = ({ bodyCompositions }) => {

  const data = useMemo(() => {
    return bodyCompositions.reduce<
      [
        { label: string; data: { date: Date; weight: number }[] },
        { label: string; data: { date: Date; weight: number }[] }
      ]
    >(
      (data, bodyComposition) => {
        const date = new Date(bodyComposition.date);
        data[0].data.push({
          date,
          weight: bodyComposition.fatFreeMass,
        });
        data[1].data.push({
          date,
          weight: bodyComposition.fatMass,
        });
        return data;
      },
      [
        {
          label: "Lean Body Mass",
          data: [],
        },
        {
          label: "Fat Mass",
          data: [],
        },
      ]
    );
  }, [bodyCompositions]);

  const primaryAxis = React.useMemo(
    (): AxisOptions<MyDatum> => ({
      getValue: ({ date }) => date,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<MyDatum>[] => [
      {
        getValue: ({ weight }) => weight,
        stacked: true,
        
        //hardMin: 60,
      },
    ],
    []
  );

  return (
    <Box height={200} width={400}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </Box>
  );
};
