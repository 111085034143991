import { MoodsChart as MoodsChartComponent } from "@modules/Moods/components";
import { useGetMoods } from "@modules/Moods/hooks";
import { CircularProgress } from "@mui/material";
import { formatIsoDate, addDays } from "@utils/date";
import { FC } from "react";

export interface MoodsChartProps {
  days: number | 'ALL';
}

export const MoodsChart: FC<MoodsChartProps> = ({ days }) => {
  const endDate = new Date();
  const startDate = days !== 'ALL' ? addDays(endDate, days) : null;

  const { isFetched, moods } = useGetMoods({
    startDate: formatIsoDate(startDate),
    endDate: formatIsoDate(endDate),
  });

  return isFetched ? (
    <MoodsChartComponent moods={moods} />
  ) : (
    <CircularProgress />
  );
};
