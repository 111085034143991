import { DailyLogSummary } from "@modules/Logs/types";
import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

type DailyLogSummaryChartDataItem = {
  date: Date;
  value: number;
};

type DailyLogSummaryChartData = {
  label: string;
  data: DailyLogSummaryChartDataItem[];
}[];

export interface DailyLogSummaryChartProps {
  dailyLogSummaries: DailyLogSummary[];
  mode: "GRAMM" | "CALORIES";
}

export const DailyLogSummaryChart: FC<DailyLogSummaryChartProps> = ({
  mode,
  dailyLogSummaries,
}) => {
  const data = useMemo(
    () =>
      dailyLogSummaries.reduce<DailyLogSummaryChartData>(
        (data, dailyLogSummary) => {
          const date = new Date(dailyLogSummary.date);
          const protein = mode === 'GRAMM' ? dailyLogSummary.protein : dailyLogSummary.protein * 4.1
          const carbs = mode === 'GRAMM' ? dailyLogSummary.carbs : dailyLogSummary.carbs * 4.1
          const fats = mode === 'GRAMM' ? dailyLogSummary.fats : dailyLogSummary.fats * 9.3
          data[0].data.push({
            date,
            value: protein,
          });
          data[1].data.push({
            date,
            value: carbs,
          });
          data[2].data.push({
            date,
            value: fats,
          });
          return data;
        },
        [
          {
            label: "Protein",
            data: [],
          },
          {
            label: "Carbs",
            data: [],
          },
          {
            label: "Fats",
            data: [],
          },
        ]
      ),
    [dailyLogSummaries, mode]
  );

  const primaryAxis = useMemo(
    (): AxisOptions<DailyLogSummaryChartDataItem> => ({
      getValue: ({ date }) => date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyLogSummaryChartDataItem>[] => [
      {
        getValue: ({ value }) => value,
        stacked: true,
      },
    ],
    []
  );

  return (
    <Box height={200} width={400}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </Box>
  );
};
