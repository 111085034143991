import { FC } from "react";
import { StepsChart } from "@modules/DailyActivities/containers";
import { ChartCard } from "@components";

type MenuOptions = {
  label: string;
  value: number | "ALL";
}[];

export const StepsCard: FC = () => {
  return (
    <ChartCard
      title="Steps"
      defaultMenuValue={"ALL"}
      menuOptions={
        [
          {
            label: "30 Days",
            value: -30,
          },
          {
            label: "90 Days",
            value: -90,
          },
          {
            label: "All",
            value: "ALL",
          },
        ] as MenuOptions
      }
    >
      {({ value }) => <StepsChart days={value} />}
    </ChartCard>
  );
};
