
import { fetchApi } from "../../utils";

export type LoginRequestConfig = {
    email: string;
    password: string;
}

export type LoginRequestResult = {
    accessToken: string;
}

export const login = async ({
    email,
    password,

  }: LoginRequestConfig) => {
    const { accessToken } = await fetchApi<LoginRequestResult>("/auth/login", {
        method: 'POST',
      body: {
        email,
        password,
      },
    });
    return accessToken;
  };