import { DailyActivity } from "@modules/DailyActivities/types";
import { Box } from "@mui/material";
import React, { FC, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

type BurnedCaloriesChartDataItem = {
  date: Date;
  calories: number;
};

type BurnedCaloriesChartData = {
  label: string;
  data: BurnedCaloriesChartDataItem[];
}[];

export interface BurnedCaloriesChartProps {
  dailyActivities: DailyActivity[];
}

export const BurnedCaloriesChart: FC<BurnedCaloriesChartProps> = ({
  dailyActivities,
}) => {
  const data = useMemo(
    () =>
      dailyActivities.reduce<BurnedCaloriesChartData>(
        (data, { date: dateStr, calories }) => {
          const date = new Date(dateStr);
          data[0].data.push({
            date,
            calories: calories.activity,
          });
          data[1].data.push({
            date,
            calories: calories.nonActivity,
          });
          return data;
        },
        [
          {
            label: "Active Calories",
            data: [],
          },
          {
            label: "Active Calories",
            data: [],
          },
        ]
      ),
    [dailyActivities]
  );

  const primaryAxis = useMemo(
    (): AxisOptions<BurnedCaloriesChartDataItem> => ({
      getValue: ({ date }) => date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<BurnedCaloriesChartDataItem>[] => [
      {
        getValue: ({ calories }) => calories,
        elementType: "area",
        stacked: true,
      },
    ],
    []
  );

  return (
    <Box height={200} width={400}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </Box>
  );
};
