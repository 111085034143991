import { ChartCard } from "@components";
import { FC } from "react";
import { BodyCompositionChart } from "@modules/BodyWeight/containers";

type MenuOptions = {
  label: string;
  value: number | 'ALL'
}[]

export const BodyCompositionCard: FC = () => {
  return (
    <ChartCard
      title="Body Composition"
      defaultMenuValue={'ALL'}
      menuOptions={[
        {
          label: "30 Days",
          value: -30,
        },
        {
          label: "90 Days",
          value: -90,
        },
        {
          label: "All",
          value: 'ALL',
        },
      ] as MenuOptions}
    >
      {({ value }) => <BodyCompositionChart days={value} />}
    </ChartCard>
  );
};
