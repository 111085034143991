import { BodyCompositionChart as BodyCompositionChartComponent } from "@modules/BodyWeight/components";
import { addDays, formatIsoDate } from "@utils/date";
import { useGetBodyComposition } from "@modules/BodyWeight/hooks";
import { CircularProgress } from "@mui/material";
import { FC } from "react";

export interface BodyWeightChartProps {
  days: number | 'ALL';
}

export const BodyCompositionChart: FC<BodyWeightChartProps> = ({ days }) => {
  const endDate = new Date();
  const startDate = days !== 'ALL' ? addDays(endDate, days) : null

  const { isFetched, bodyCompositions } = useGetBodyComposition({
    startDate: formatIsoDate(startDate),
    endDate: formatIsoDate(endDate),
  });

  return isFetched ? (
    <BodyCompositionChartComponent bodyCompositions={bodyCompositions} />
  ) : (
    <CircularProgress />
  );
};
