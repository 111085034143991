import { FC, useMemo } from "react";
import { MuscleStrengthTrendChart } from "@modules/Muscles/containers";
import { useMuscles } from "@modules/Muscles/hooks";
import { ChartCard } from "@components";

export const MuscleStrengthTrendCard: FC = () => {
  const { muscles } = useMuscles();

  const menuOptions = useMemo(
    () => muscles.map((muscle) => ({ label: muscle.name, value: muscle.id })),
    [muscles]
  );

  return (
    <ChartCard
      title="Muscle Strength Trend"
      defaultMenuValue={1}
      menuOptions={menuOptions}
    >
      {({ value }) => <MuscleStrengthTrendChart muscleId={value} />}
    </ChartCard>
  );
};
