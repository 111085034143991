import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { WorkloadPerWeekChart as WorkloadPerWeekChartComponent } from "@modules/Muscles/components";
import { useGetWorkloadPerWeek } from "@modules/Muscles/hooks";

export interface WorkloadPerWeekChartProps {
  muscleId: number;
}

export const WorkloadPerWeekChart: FC<WorkloadPerWeekChartProps> = ({
  muscleId,
}) => {
  const { isFetched, workloadPerWeek } = useGetWorkloadPerWeek({
    muscleId,
  });

  return isFetched ? (
    <WorkloadPerWeekChartComponent workloadPerWeek={workloadPerWeek} />
  ) : (
    <CircularProgress />
  );
};
