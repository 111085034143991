import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { WorkloadPerWeek } from "@modules/Muscles/types";
import { useQuery } from "react-query";

export type UseGetWorkloadPerWeekConfig  = {
    muscleId: number;
}

export const useGetWorkloadPerWeek = ({ muscleId }: UseGetWorkloadPerWeekConfig) => {
    const { accessToken } = useAccessTokenContext();
    const { data = [], ...query } = useQuery<WorkloadPerWeek[]>(["workloadPerWeek", muscleId], () =>
      MyFitAppApi.getWorkloadPerWeek({ accessToken, muscleId })
    );
    return { ...query, workloadPerWeek: data };
}