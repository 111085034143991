import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateProvider, PublicProvider } from "./providers";
import ROUTES from "./routes";

function App() {
  return (
    <PublicProvider>
      <BrowserRouter>
        <Routes>
          {ROUTES.public.map(({ path, page: Page }) => (
            <Route key={path} path={path} element={<Page />} />
          ))}
          {ROUTES.private.map(({ path, page: Page }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateProvider>
                  <Page />
                </PrivateProvider>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </PublicProvider>
  );
}

export default App;
