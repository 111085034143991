import { AccessTokenProvider, AlertProvider, ConfirmProvider } from "@contexts";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { PrivacyProvider } from "@modules/Auth";

const darkTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const queryClient = new QueryClient();

export interface PublicProviderProps {
  children: React.ReactNode;
}

export const PublicProvider: FC<PublicProviderProps> = ({ children }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AccessTokenProvider>
          <AlertProvider>
            <ConfirmProvider>{children}</ConfirmProvider>
          </AlertProvider>
        </AccessTokenProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export interface PrivateProviderProps {
  children: React.ReactNode;
}

export const PrivateProvider: FC<PrivateProviderProps> = ({ children }) => {
  return <PrivacyProvider>{children}</PrivacyProvider>;
};
