import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { MuscleStrengthTrend } from "@modules/Muscles/types";
import { useQuery } from "react-query";

export type UseGetMuscleStrengthTrend = {
  muscleId: number;
}

export const useGetMuscleStrengthTrend = ({ muscleId }: UseGetMuscleStrengthTrend) => {
    const { accessToken } = useAccessTokenContext();
    const { data = [], ...query } = useQuery<MuscleStrengthTrend[]>(["muscleStrengthTrend", muscleId], () =>
      MyFitAppApi.getMuscleStrengthTrend({ accessToken, muscleId })
    );
    return { ...query, muscleStrengthTrends: data };
}