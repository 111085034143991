import { WorkloadPerWeek } from "@modules/Muscles/types";
import { fetchApi } from "../../utils";

export type getBodyCompositionRequestConfig = {
    accessToken: string;
    muscleId: number;
}

export type getBodyCompositionRequestResult = {
    workload: WorkloadPerWeek[];
}

export const getWorkloadPerWeek = async ({
    accessToken,
    muscleId
  }: getBodyCompositionRequestConfig) => {
    const { workload } = await fetchApi<getBodyCompositionRequestResult>("/muscles/workloadPerWeek", {
        accessToken,
      params: {
        muscleId,
      },
    });
    return workload;
  };