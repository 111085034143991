import { FC } from "react";
import LoginPage from "@pages/LoginPage/LoginPage";
import StartPage from "@pages/StartPage/StartPage";


type Page = {
  path: string;
  page: FC;
};

const ROUTES: { public: Page[]; private: Page[] } = {
  public: [
    {
      path: "/login",
      page: LoginPage,
    },
  ],
  private: [
    {
      path: "/",
      page: StartPage,
    },
  ],
};

export default ROUTES;
