import * as AuthApi from "./auth";
import * as BodyWeightApi from "./bodyWeights";
import * as MoodsApi from "./moods";
import * as MusclesApi from "./muscles";
import * as LogsApi from "./logs";
import * as DailyActivitiesApi from "./dailyActivities";

const MyFitAppApi = {
  ...AuthApi,
  ...BodyWeightApi,
  ...MoodsApi,
  ...MusclesApi,
  ...LogsApi,
  ...DailyActivitiesApi
};

export default MyFitAppApi;
