import { Column, ColumnProps } from "@components";
import { FC } from "react";

export interface CardProps extends ColumnProps {}

export const Card: FC<CardProps> = ({ children, sx, ...props }) => {
  return (
    <Column
      component="article"
      sx={{
        mt: 1,
        borderRadius: 5,
        boxShadow: 6,
        p: 2,
        bgcolor: "background.card",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Column>
  );
};
