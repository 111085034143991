import { BurnedCaloriesChart as BurnedCaloriesChartComponent } from "@modules/DailyActivities/components";
import { addDays, formatIsoDate } from "@utils/date";
import { useGetDailyActivities } from "@modules/DailyActivities/hooks";
import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { Text } from "@components";

export interface BurnedCaloriesChartProps {
  days: number | "ALL";
}

export const BurnedCaloriesChart: FC<BurnedCaloriesChartProps> = ({ days }) => {
  const endDate = new Date();
  const startDate = days !== "ALL" ? addDays(endDate, days) : null;

  const { isFetched, dailyActivities } = useGetDailyActivities({
    startDate: formatIsoDate(startDate),
    endDate: formatIsoDate(endDate),
  });

  return isFetched ? (
    dailyActivities.length > 0 ? (
      <BurnedCaloriesChartComponent dailyActivities={dailyActivities} />
    ) : (
      <Text>no data</Text>
    )
  ) : (
    <CircularProgress />
  );
};
