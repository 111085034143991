import { DailyActivity } from "@modules/DailyActivities/types";
import { Box } from "@mui/material";
import React, { FC, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

export interface StepsChartProps {
  dailyActivities: DailyActivity[];
}

export const StepsChart: FC<StepsChartProps> = ({ dailyActivities }) => {
  const primaryAxis = useMemo(
    (): AxisOptions<DailyActivity> => ({
      getValue: ({ date }) => new Date(date),
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyActivity>[] => [
      {
        getValue: ({ steps }) => steps,
        elementType: "line",
      },
    ],
    []
  );

  return (
    <Box height={200} width={400}>
      <Chart
        options={{
          data: [
            {
              label: "Steps",
              data: dailyActivities,
            },
          ],
          primaryAxis,
          secondaryAxes,
        }}
      />
    </Box>
  );
};
