import { Page } from "@components";
import { BodyCompositionCard } from "@modules/BodyWeight/containers";
import { BurnedCaloriesCard, StepsCard } from "@modules/DailyActivities/containers";
import { DailyLogSummaryCard } from "@modules/Logs/containers";
import { MoodsCard } from "@modules/Moods/containers";
import { MuscleStrengthTrendCard, WorkloadPerWeekCard } from "@modules/Muscles/containers";

const StartPage = () => {
  
    return (
      <Page sx={{ p: 2, flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
        <BodyCompositionCard/>
        <WorkloadPerWeekCard/>
        <MoodsCard/>
        <DailyLogSummaryCard/>
        <MuscleStrengthTrendCard/>
        <StepsCard/>
        <BurnedCaloriesCard/>
      </Page>
    );
  };
  
  export default StartPage;