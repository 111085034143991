import { Mood } from "@modules/Moods/types";
import { fetchApi } from "../../utils";

export type GetMoodsRequestConfig = {
    accessToken: string;
    startDate: string | null;
    endDate: string | null;
}

export type GetMoodsRequestResult = {
  moods: Mood[];
}

export const getMoods = async ({
    accessToken,
    startDate,
    endDate
  }: GetMoodsRequestConfig) => {
    const { moods } = await fetchApi<GetMoodsRequestResult>("/moods", {
        accessToken,
      params: {
        startDate,
        endDate,
      },
    });
    return moods;
  };