import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { MuscleStrengthTrendChart as MuscleStrengthTrendChartComponent } from "@modules/Muscles/components";
import { useGetMuscleStrengthTrend } from "@modules/Muscles/hooks";
import { Text } from "@components";

export interface MuscleStrengthTrendChartProps {
  muscleId: number;
}

export const MuscleStrengthTrendChart: FC<MuscleStrengthTrendChartProps> = ({
  muscleId,
}) => {
  const { isFetched, muscleStrengthTrends } = useGetMuscleStrengthTrend({
    muscleId,
  });

  return isFetched ? muscleStrengthTrends.length > 0 ? (
    <MuscleStrengthTrendChartComponent muscleStrengthTrends={muscleStrengthTrends} />
  ) : (
    <Text>no data</Text>
  ) : (
    <CircularProgress />
  );
};
