import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { DailyActivity } from "@modules/DailyActivities/types";
import { useQuery } from "react-query";

export type UseGetDailyActivitiesConfig  = {
    startDate: string | null;
    endDate: string | null;
}

export const useGetDailyActivities = ({ startDate, endDate }: UseGetDailyActivitiesConfig) => {
    const { accessToken } = useAccessTokenContext();
    const { data = [], ...query } = useQuery<DailyActivity[]>(["dailyActivities", startDate, endDate], () =>
      MyFitAppApi.getDailyActivities({ accessToken, startDate, endDate })
    );
    return { ...query, dailyActivities: data };
}