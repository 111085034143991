import { WorkloadPerWeek } from "@modules/Muscles/types";
import { Box } from "@mui/material";
import React, { FC } from "react";
import { AxisOptions, Chart } from "react-charts";

export interface WorkloadPerWeekChartProps {
  workloadPerWeek: WorkloadPerWeek[];
}

export const WorkloadPerWeekChart: FC<WorkloadPerWeekChartProps> = ({
  workloadPerWeek,
}) => {
  const primaryAxis = React.useMemo(
    (): AxisOptions<WorkloadPerWeek> => ({
      getValue: ({ week }) => week,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<WorkloadPerWeek>[] => [
      {
        getValue: ({ sets }) => sets,

        elementType: 'line'
      },
    ],
    []
  );

  return (
    <Box height={200} width={400}>
      <Chart
        options={{
          data: [
            {
              label: "Workload Per Week",
              data: workloadPerWeek,
              color: 'blue',
            },
            {
              label: "Minimum Volume",
              data: workloadPerWeek.map(({ week }) => ({ week, sets: 10 })),
              color: 'green'
            },
            {
              label: "Maximum Volume",
              data: workloadPerWeek.map(({ week }) => ({ week, sets: 20 })),
              color: 'red'
            },
          ],
          primaryAxis,
          secondaryAxes,
        }}
      />
    </Box>
  );
};
