import { BodyComposition } from "@modules/BodyWeight/types";
import { fetchApi } from "../../utils";

export type GetBodyCompositionRequestConfig = {
    accessToken: string;
    startDate: string | null;
    endDate: string | null;
}

export type GetBodyCompositionRequestResult = {
  composition: BodyComposition[];
}

export const getBodyComposition = async ({
    accessToken,
    startDate,
    endDate
  }: GetBodyCompositionRequestConfig) => {
    const { composition } = await fetchApi<GetBodyCompositionRequestResult>("/bodyWeights/composition", {
        accessToken,
      params: {
        startDate,
        endDate,
      },
    });
    return composition;
  };