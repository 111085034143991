import { DailyLogSummary } from "@modules/Logs/types";
import { fetchApi } from "../../utils";

export type GetDailyLogSummaryRequestConfig = {
    accessToken: string;
}

export type GetDailyLogSummaryRequestResult = {
    dailySummary: DailyLogSummary[];
}

export const getDailyLogSummary = async ({
    accessToken,
  }: GetDailyLogSummaryRequestConfig) => {
    const { dailySummary } = await fetchApi<GetDailyLogSummaryRequestResult>("/logs/dailySummary", {
        accessToken,
    });
    return dailySummary;
  };