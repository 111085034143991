import { ChartCard } from "@components";
import { FC, useMemo } from "react";
import { WorkloadPerWeekChart } from "@modules/Muscles/containers";
import { useMuscles } from "@modules/Muscles/hooks";

export const WorkloadPerWeekCard: FC = () => {
  const { muscles } = useMuscles();

  const menuOptions = useMemo(() => muscles.map(muscle => ({ label: muscle.name, value: muscle.id })), [muscles])

  return (
    <ChartCard
      title="Workload Per Muscle"
      defaultMenuValue={1}
      menuOptions={menuOptions}
    >
      {({ value }) => <WorkloadPerWeekChart muscleId={typeof value === 'string' ? 1 : value} />}
    </ChartCard>
  );
};
